


import { $getContentPriceHq } from "@/api/modules/price_hq.js";
import { defineStore } from 'pinia'
export const usePriceHq = defineStore('priceHq', {
    state() {
        return {
            priceHqList: [], // 列表
            total: 0, // 总数
            params: {
                limit: 30,
                start: '',
                end: '',
                region_id: '',//地区id
                info: ''//关键词
            },
            loading: false,
            homeHq: {
                hq1: [],
                hq2: []
            }
        }
    },
    actions: {
        async getPriceHq(params, falg) {
            this.loading = true
            let res = await $getContentPriceHq({
                ...params,
                ...this.params,
                limit: falg == 'home' ? '20' : '30'
            })
            if (res.status) {
                this.priceHqList = res.data.content
                this.homeHq.hq1 = res.data.content.slice(0, 10)
                this.homeHq.hq2 = res.data.content.slice(10, 20)
                this.total = res.data.total
                this.loading = false
            }
        }
    }
})